<template>
  <div class="about-page">
    <Nav />
    <div class="about-wrap">
      <div class="top-bg"></div>
      <div class="about-main">
        <div class="custom-vant-tab tab-wrap">
          <van-tabs type="card" v-model="activeName">
            <van-tab title="品牌故事">
              <div class="story">
                <h1>关于浩亭</h1>
                <p>
                  1945 年 9 月 1 日，威廉 (Wilhelm Harting) 和 玛丽 (Marie
                  Harting) 创办了名为 Wilhelm Harting Mechanical WorkeShop
                  的公司。在明登修理厂的一个约 100 m2
                  的小门厅里，他们开始生产满足日常需求的设备。最初，他们所生产的只是日常用品。他们生产节能灯泡、广受欢迎的电炉、电动围栏设备、华夫饼烤模、电点火器和熨斗。随着订单规模和范围的不断扩大，公司也实现了稳步增长。
                </p>
                <h3>关于浩亭</h3>
                <p>
                  创始人 威廉
                  很早就认识到，新兴的德国工业需要技术产品。在敬业的员工和创新想法的帮助下，他们开始朝着开发坚固、易于操作且适用于各种用途的连接器的目标迈进。威廉
                  早就认识到人们需要这种产品。
                </p>
                <img src="../../image/story-01.png" alt="" />
                <p>
                  此后不久, Han®
                  连接器就在市场中亮相并赢得人们的青睐，继而建立了全球公认的标准。在每次变革中，他都是浩亭技术集团的坚强后盾。
                  在此期间，浩亭技术集团 成长为工业技术的三大生命线 -
                  数据、信号和电源 - 的全球领先解决连接解决方案供应商,
                  在全球拥有15 个生产基地和 44 个销售公司。
                  该公司还生产用于零售的电子收款机、用于汽车和工业批量生产的电磁执行器、用于电动汽车的充电技术设备和电缆，以及用于自动化、机器人和交通领域客户及应用的硬件和软件等。
                  “我不希望看到任何产品遭到退货。” 威廉
                  的这些话清楚地表明，他们在当时与今天一样重视出色的产品质量。
                  在此处了解有关浩亭技术集团发展历程的更多信息。
                </p>
                <p class="spec">过去成就未来</p>
                <h3>三代企业家</h3>
                <p>
                  自公司成立以来，Harting
                  家族一直以对细节的极大热情领导着公司的发展。1962 年
                  威廉去世后，玛丽继续领导公司取得成功而有目的的发展。1967 年和
                  1969 年，两个儿子 洪狄马 (Dietmar) 和 约根 (Jürgen
                  Harting)（1973
                  年去世）与母亲一起担负起引领企业发展的重任。1987 年，洪玛嘉
                  (Margrit Harting)
                  加入了她丈夫洪狄马的家族企业，作为普通合伙人服务至今。 洪斐立
                  (Philip F. W. Harting) 和 韩洪玛莎 (Maresa W. M.
                  Harting-Hertz) 现在是第三代企业领导人的代表。自 2015 年 10
                  月起，洪斐立 一直担任 浩亭技术集团 的董事会主席
                  。到目前为止，他们四人共同进行公司决策。
                </p>
                <div class="man-about">
                  <div class="head">
                    <img src="../../image/story-head.png" alt="" />
                  </div>
                  <div>
                    <div class="man-title">Margrit Harting</div>
                    <div class="man-tag">董事会成员和合伙人</div>
                  </div>
                </div>
                <div class="quote">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="10"
                    viewBox="0 0 13 10"
                    fill="none"
                    style="margin-bottom: 9px"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.1963 1.56182C10.3695 2.42951 9.4562 3.44902 9.4562 4.62039C10.2348 4.70716 10.8786 5.01446 11.3877 5.5423C11.8968 6.07014 12.1514 6.68113 12.1514 7.37527C12.1514 8.1128 11.9043 8.73463 11.4102 9.24078C10.9161 9.74693 10.2947 10 9.54604 10C8.70754 10 7.98135 9.67101 7.36745 9.01302C6.75355 8.35502 6.4466 7.55604 6.4466 6.61605C6.4466 3.79608 8.07866 1.59075 11.3428 0L12.1963 1.56182ZM5.74967 1.56182C3.90797 2.42951 2.98714 3.44902 2.98714 4.62039C3.78071 4.70716 4.43204 5.01446 4.94113 5.5423C5.45021 6.07014 5.70475 6.68113 5.70475 7.37527C5.70475 8.1128 5.45396 8.73463 4.95236 9.24078C4.45075 9.74693 3.82563 10 3.07697 10C2.23848 10 1.51603 9.67101 0.909616 9.01302C0.303202 8.35502 0 7.55604 0 6.61605C0 3.79608 1.62457 1.59075 4.87375 0L5.74967 1.56182Z"
                      fill="#FFCC00"
                    />
                  </svg>
                  <p>
                    家族中最重要的成员是公司 -
                    它是女主角。它由最高层的人员来代表。每个人都有自己的权利和义务。我们拥有长期的业务战略。我们从几代人的角度思考问题。
                  </p>
                  <div class="end-box">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="10"
                      viewBox="0 0 13 10"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.803722 8.43818C2.63045 7.57049 3.5438 6.55098 3.5438 5.37961C2.7652 5.29284 2.12136 4.98554 1.61227 4.4577C1.10318 3.92986 0.848642 3.31887 0.848642 2.62473C0.848642 1.8872 1.0957 1.26537 1.58981 0.759218C2.08393 0.253069 2.7053 -9.53674e-07 3.45396 -9.53674e-07C4.29246 -9.53674e-07 5.01865 0.328991 5.63255 0.986984C6.24645 1.64498 6.5534 2.44396 6.5534 3.38395C6.5534 6.20392 4.92134 8.40925 1.65719 10L0.803722 8.43818ZM7.25033 8.43818C9.09203 7.57049 10.0129 6.55098 10.0129 5.37961C9.21929 5.29284 8.56796 4.98554 8.05887 4.4577C7.54979 3.92986 7.29525 3.31887 7.29525 2.62473C7.29525 1.8872 7.54604 1.26537 8.04764 0.759218C8.54925 0.253069 9.17437 -9.53674e-07 9.92303 -9.53674e-07C10.7615 -9.53674e-07 11.484 0.328991 12.0904 0.986984C12.6968 1.64498 13 2.44396 13 3.38395C13 6.20392 11.3754 8.40925 8.12625 10L7.25033 8.43818Z"
                        fill="#FFCC00"
                      />
                    </svg>
                  </div>
                </div>
                <h3>从车库到全球化公司</h3>
                <p>
                  公司起源于让人们的日常生活更轻松的美好愿望。创新实力、坚定承诺和创业愿景为公司发展成全球性的成功企业铺平了道路。我们在全球各地拥有约
                  6,200
                  名员工，他们每天都在努力奋进，确保我们不会满足于现状，而是专注于实现进一步创新和发展目标。在此过程中，我们使用公司愿景作为行动指南：
                </p>
                <div class="quote">
                  <h4>我们致力于</h4>
                  <p class="p-li"><span></span>用以人为本的技术构建未来</p>
                  <p class="p-li"><span></span>成为全球性企业</p>
                  <p class="p-li"><span></span>为人类创造价值</p>
                  <p class="spec01">我们的企业愿景形成于 1996 年</p>
                </div>
                <div class="en-doc">
                  <img src="../../image/pdf-icon.png" alt="" />
                  <p class="doc-name">Flyer - Our Mission (en.)</p>
                </div>
              </div>
            </van-tab>
            <van-tab title="社会责任">
              <div class="duty">
                <h1>企业社会责任</h1>
                <p>
                  对我们而言，经营一家成功的企业也意味着要对社会负责。
                  我们公司已经接受了这一挑战。
                  因此，您可以确定您正在与供应链中的负责合作伙伴合作。
                </p>
                <div
                  v-for="(card, index) in cardList"
                  :key="index"
                  class="card"
                >
                  <img :src="card.imgUrl" alt="" />
                  <div class="content">
                    <h4>{{ card.title }}</h4>
                    <p>{{ card.brief }}</p>
                    <div class="btn-more" @click="goMore(card)">
                      <a href="https://baidu.com">了解更多</a>
                    </div>
                  </div>
                </div>
              </div>
            </van-tab>
            <van-tab title="加入我们">
              <div class="join">
                <div class="join-about">
                  <h1>浩亭的职位空缺</h1>
                  <svg
                    class="arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.3536 16.3536C16.4015 16.3056 16.4377 16.2504 16.4621 16.1914C16.4861 16.1333 16.4996 16.0697 16.5 16.003L16.5 16V15.9995V8C16.5 7.72386 16.2761 7.5 16 7.5C15.7239 7.5 15.5 7.72386 15.5 8V14.7929L8.35355 7.64645C8.15829 7.45118 7.84171 7.45118 7.64645 7.64645C7.45118 7.84171 7.45118 8.15829 7.64645 8.35355L14.7929 15.5H8C7.72386 15.5 7.5 15.7239 7.5 16C7.5 16.2761 7.72386 16.5 8 16.5H15.9995H16C16.008 16.5 16.016 16.4998 16.024 16.4994C16.0811 16.4967 16.1378 16.4842 16.1914 16.4621C16.2504 16.4377 16.3056 16.4015 16.3536 16.3536Z"
                      fill="#CDD7DE"
                    />
                  </svg>
                  <h3>加入浩亭大家庭</h3>
                  <p>
                    您是否愿意为一个老板与员工保持亲密联系的家族企业工作？您是否愿意进入一家帮助塑造美好未来的公司？让我们共同携手，为內部和外部客户提供最佳的解决方案！
                  </p>
                  <h3>浩亭为何会成为一个未来导向的雇主？</h3>
                  <p style="margin-bottom: 8px">
                    浩亭是一个具有发展前途的工作场所！我们提供长期工作。条理清晰的入职计划使您可以更轻松地在这里开展工作
                    —
                    而且您的导师也会随时为您提供协助和帮助。我们的工作能够开拓您的视野！
                    在浩亭，您能夠继续学习 —
                    无论是根据个人需求量身定制的个人成长计划、专家职业发展道路，还是其他个人成长进程。让我们帮助您发现自己的潜能！
                    德国东威斯特伐利亚地区不仅拥有美丽的乡村环境，同时还可方便地前往其他城市。这里也是享受安全家庭生活的好地方。
                    欢迎加入我们！
                  </p>
                  <div class="btn-more">www.karriere.HARTING.de</div>
                </div>

                <div class="advantage">
                  <h2>作为雇主，我们拥有多种实力优势</h2>
                  <p class="en-title">People | Power | Partnership</p>
                  <svg
                    class="arrow"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.3536 16.3536C16.4015 16.3056 16.4377 16.2504 16.4621 16.1914C16.4861 16.1333 16.4996 16.0697 16.5 16.003L16.5 16V15.9995V8C16.5 7.72386 16.2761 7.5 16 7.5C15.7239 7.5 15.5 7.72386 15.5 8V14.7929L8.35355 7.64645C8.15829 7.45118 7.84171 7.45118 7.64645 7.64645C7.45118 7.84171 7.45118 8.15829 7.64645 8.35355L14.7929 15.5H8C7.72386 15.5 7.5 15.7239 7.5 16C7.5 16.2761 7.72386 16.5 8 16.5H15.9995H16C16.008 16.5 16.016 16.4998 16.024 16.4994C16.0811 16.4967 16.1378 16.4842 16.1914 16.4621C16.2504 16.4377 16.3056 16.4015 16.3536 16.3536Z"
                      fill="#CDD7DE"
                    />
                  </svg>
                  <div class="quote">
                    <h4>People</h4>
                    <p class="p-li">
                      <span></span
                      >我们知道员工是公司取得成功的基础。这就是我们将注意力集中在员工身上的原因所在。对于我们来说，作为雇主的责任感和可靠性与维持激励性的工作环境同样重要。
                    </p>
                    <p class="p-li">
                      <span></span
                      >我们通过灵活的工作时间模式促进研究，鼓励您参加进修培训来为您提供支持。
                    </p>
                  </div>
                  <div class="quote">
                    <h4>Power</h4>
                    <p class="p-li">
                      <span></span
                      >我们在市场中的领先地位和在全球各地都畅销的浩亭产品都证实了我们的成功：前瞻性产品是令企业成长的先决条件。这就是我们将创新力量投入到未来技术的开发、生产和销售方面的原因所在
                    </p>
                    <p class="p-li">
                      <span></span
                      >所有这一切都是通过我们公司员工的创造力和热情来实现的。而让浩亭保持活力的正是员工的力量。
                    </p>
                    <p class="spec01">对我们而言，力量意味着创新和进步。</p>
                  </div>
                  <div class="quote">
                    <h4>Partnership</h4>
                    <p class="p-li">
                      <span></span>可靠性和责任感 –
                      我们的行动基于国内和国际合作。
                    </p>
                    <p class="p-li">
                      <span></span
                      >我们为员工提供长期规划安全性以及全面的健康福利。
                    </p>
                    <p class="p-li">
                      <span></span>
                      在与业务合作伙伴和整个社会打交道时，我们都会遵守
                      <b>对社会负责的公司治理原则</b
                      >（符合德国电子工业中央协会的行为准则）。
                    </p>
                    <p class="p-li">
                      <span></span>我们通过我们的<b>认证</b>（ISO 14001 和 EMAS
                      III）对环境和气候保护做出坚定承诺。
                    </p>
                    <p class="p-li">我们还通过社会承诺来支持我们的地区。</p>
                  </div>
                </div>
              </div>
            </van-tab>
          </van-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Nav from "../../component/nav.vue";
import { Tab, Tabs } from "vant";
export default {
  name: "ABOUT",
  components: {
    Nav,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
  },
  data() {
    return {
      activeName: 0,
      cardList: [
        {
          imgUrl: require("../../image/duty-01.png"),
          title: "环境保护和环境管理体系",
          brief: "有约束力的环境和气候保护承诺是我们成功发展的关键。",
          moreUrl: "xxxxxxxx",
        },
        {
          imgUrl: require("../../image/duty-01.png"),
          title: "环境保护和环境管理体系",
          brief:
            "注重工作健康和安全、谨慎管理环境和以节约资源的方式使用能源是我们的指导原则。这些指导原则适用于浩亭技术集团的所有活动和分支机构。",
          moreUrl: "xxxxxxxx",
        },
        {
          imgUrl: require("../../image/duty-01.png"),
          title: "环境保护和环境管理体系",
          brief:
            "注重工作健康和安全、谨慎管理环境和以节约资源的方式使用能源是我们的指导原则。这些指导原则适用于浩亭技术集团的所有活动和分支机构。",
          moreUrl: "xxxxxxxx",
        },
        {
          imgUrl: require("../../image/duty-01.png"),
          title: "环境保护和环境管理体系",
          brief:
            "注重工作健康和安全、谨慎管理环境和以节约资源的方式使用能源是我们的指导原则。这些指导原则适用于浩亭技术集团的所有活动和分支机构。",
          moreUrl: "xxxxxxxx",
        },
      ],
    };
  },
  methods: {
    toggleTab(name) {
      this.tabActive = name;
    },
    goMore(card) {
      console.log(card);
    },
  },
};
</script>
<style lang="less" scoped>
.about-page {
  width: 100%;
  min-height: 100vh;
  background: #cdd7de;
}
.about-wrap {
  position: relative;
  padding-top: 40px;
  width: 100%;
  .top-bg {
    width: 100%;
    height: 160px;
    background: url("../../image/about-bg.png") no-repeat center center / cover;
  }
  .about-main {
    padding-bottom: 40px;
    width: 100%;
    border-radius: 40px 40px 0px 0px;
    border-top: 2px solid #fff;
    background: linear-gradient(180deg, #f0f5f7 0%, #fff 87.52%);
    .tab-wrap {
      margin-bottom: 32px;
      padding-top: 40px;
      h1 {
        margin-bottom: 8px;
        color: var(--, #212027);
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%;
      }
      h2 {
        color: #212027;
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 33.6px */
      }
      h3 {
        margin-bottom: 16px;
        color: var(--, #212027);
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 28.8px */
      }
      p {
        margin-bottom: 32px;
        color: var(----, #535359);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
      }
      .quote {
        margin-bottom: 32px;
        padding: 16px;
        border-radius: 24px;
        background: #f0f3f4;
        p {
          margin-bottom: 0;
          span {
            margin: 0 4px 0 6px;
            display: inline-block;
            width: 4px;
            height: 4px;
            background: #fc0;
            vertical-align: middle;
          }
        }
        .p-li {
          margin-bottom: 16px;
          b {
            color: #212027;
            font-size: 15px;
            font-weight: 600;
            text-decoration-line: underline;
          }
        }
        .spec01 {
          color: #212027;
          font-weight: 600;
          line-height: 160%; /* 22.4px */
        }
        .end-box {
          display: flex;
          justify-content: flex-end;
        }
        h4 {
          margin-bottom: 16px;
          color: #212027;
          /* 小标题 */
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 21.6px */
        }
      }
      .story {
        padding: 35px 32px;

        img {
          margin-bottom: 32px;
          display: block;
          width: 100%;
          object-fit: cover;
        }
        .spec {
          color: var(---, #fabf00);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 19.2px */
          text-decoration-line: underline;
        }
        .man-about {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          .head {
            margin-right: 10px;
            img {
              margin-bottom: 0;
              display: block;
              width: 60px;
              height: 60px;
            }
          }
          .man-title {
            color: var(---, #fabf00);
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
          .man-tag {
            color: var(--, #212027);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
        .en-doc {
          display: flex;
          align-items: center;
          box-sizing: border-box;
          width: 100%;
          padding: 12px;
          border-radius: 12px;
          border: 1px solid var(--, #fff);
          background: var(--, #fff);

          /* 卡片投影 */
          box-shadow: 0px 0px 16px 0px #d7d9dc;
          img {
            margin: 0;
            margin-right: 8px;
            display: block;
            width: 38px;
            height: 38px;
          }
          .doc-name {
            margin: 0;
            color: var(---, #212027);
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%; /* 19.2px */
          }
        }
      }
      .duty {
        padding: 35px 32px;
        .card {
          margin-bottom: 32px;
          overflow: hidden;
          border-radius: 24px;
          border: 1px solid var(---, #fff);
          background: var(---, #fff);
          box-shadow: 0px 0px 16px 0px #d7d9dc;
          img {
            display: block;
            width: 100%;
            height: 200px;
          }
          .content {
            padding: 16px;
            h4 {
              margin-bottom: 8px;
              color: #212027;
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: 120%; /* 21.6px */
            }
            p {
              margin-bottom: 16px;
              color: #505054;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 160%; /* 22.4px */
            }
            .btn-more {
              box-sizing: border-box;
              display: flex;
              width: 160px;
              height: 40px;
              padding: 12px 24px;
              justify-content: center;
              align-items: center;
              border-radius: 40px;
              background: #fc0;
              color: #212027;
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
      .join {
        .join-about {
          padding: 35px 32px;
        }
        .arrow {
          margin-bottom: 32px;
        }
        .btn-more {
          margin-bottom: 60px;
          padding: 12px 24px;
          color: #212027;
          font-size: 16px;
          font-weight: 600;
          line-height: 120%; /* 19.2px */
          border-radius: 40px;
          background: #fc0;
        }
        .advantage {
          padding: 40px 32px;
          border-radius: 40px 40px 0px 0px;
          border-top: 2px solid #fff;
          background: linear-gradient(180deg, #f0f5f7 0%, #fff 87.52%);
          .en-title {
            padding: 8px 0;
            color: #505054;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }
}
</style>
